import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { CloudFile, CloudSortObject, DriveApi, DropboxApi, OnedriveApi } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import MLDialog from "../../../../../utils/components/poppers";
import { useApi } from "../../../../../utils/hooks/api";
import { CloudSource } from "../ImportFromCloudContentModal";
import { AxiosError, AxiosResponse } from "axios";

export const useGetCloudDocs = ({
	enabledCloud,
	search,
	parentFolderId,
	sortObject,
}: {
	enabledCloud: CloudSource | undefined;
	search?: string;
	parentFolderId?: string;
	sortObject: CloudSortObject | undefined;
}) => {
	const { t } = useTranslation();
	const driveApi = useApi(DriveApi);
	const dropboxApi = useApi(DropboxApi);
	const onedriveApi = useApi(OnedriveApi);

	return useInfiniteQuery({
		queryKey: ["CloudFolders", enabledCloud, search, parentFolderId, JSON.stringify(sortObject)],
		queryFn: ({ pageParam }) => {
			if (enabledCloud === "googleDrive") {
				return driveApi.getDriveFolder(pageParam ?? "", search, parentFolderId ?? "", JSON.stringify(sortObject));
			} else if (enabledCloud === "dropbox") {
				return dropboxApi.getDropboxFolder(pageParam ?? "", search, parentFolderId ?? "", JSON.stringify(sortObject));
			}
			return onedriveApi.getOneDriveData(pageParam ?? "", search, parentFolderId ?? "", JSON.stringify(sortObject));
		},
		getNextPageParam: (lastPage) => {
			return Boolean(lastPage?.headers?.["x-next-page-token"]) ? lastPage?.headers?.["x-next-page-token"] : null;
		},
		onError: () => {
			MLDialog.showSnackbar(t("cloudImport.errorInCloudDocs"), { variant: "error" });
		},
		enabled: Boolean(enabledCloud),
	});
};

export const useGetAllCloudRootLevelDocs = ({
	enabledCloud,
	onAuthSuccess,
	onAuthError,
}: {
	enabledCloud: CloudSource | undefined;
	onAuthSuccess?: () => void;
	onAuthError?: () => void;
}) => {
	const { t } = useTranslation();
	const driveApi = useApi(DriveApi);
	const dropboxApi = useApi(DropboxApi);
	const onedriveApi = useApi(OnedriveApi);

	return useMutation({
		mutationFn: async () => {
			const getFolder = async (pageToken?: string) => {
				if (enabledCloud === "googleDrive") {
					return driveApi.getDriveFolder(pageToken, undefined, undefined);
				} else if (enabledCloud === "dropbox") {
					return dropboxApi.getDropboxFolder(pageToken, undefined, undefined);
				}
				return onedriveApi.getOneDriveData(pageToken, undefined, undefined);
			};
			const allDocs = [];

			let pageToken = undefined;
			let hasNextPage = false;
			do {
				const { data, headers } = (await getFolder(pageToken)) as AxiosResponse<CloudFile[]>;
				// @ts-ignore
				allDocs.push(...data);
				pageToken = headers?.["x-next-page-token"];
				hasNextPage = JSON.parse(headers?.["has-next-page"]);
			} while (pageToken && hasNextPage);
			return allDocs;
		},
		onError: (err: AxiosError) => {
			// TODO MEM: da rivedere
			if (err?.response?.status === 403) {
				onAuthError?.();
			} else {
				MLDialog.showSnackbar(t("cloudImport.errorInCloudDocs"), { variant: "error" });
			}
		},
		onSuccess: () => {
			onAuthSuccess?.();
		},
	});
};
