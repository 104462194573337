"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchApi = exports.SearchApiFactory = exports.SearchApiFp = exports.SearchApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * SearchApi - axios parameter creator
 * @export
 */
const SearchApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get search global audits
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalAudits: (qry, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/search/global/audits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (qry !== undefined) {
                localVarQueryParameter['qry'] = qry;
            }
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get search global contacts
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalContacts: (qry, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/search/global/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (qry !== undefined) {
                localVarQueryParameter['qry'] = qry;
            }
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get search global documents
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalDocuments: (qry, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/search/global/document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (qry !== undefined) {
                localVarQueryParameter['qry'] = qry;
            }
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get search global folders
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalFolders: (qry, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/search/global/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (qry !== undefined) {
                localVarQueryParameter['qry'] = qry;
            }
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get search tags
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchTags: (qry, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/search/global/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (qry !== undefined) {
                localVarQueryParameter['qry'] = qry;
            }
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Search global
         * @param {string} [qry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGlobal: (qry, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/search/global/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (qry !== undefined) {
                localVarQueryParameter['qry'] = qry;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Search users or contacts
         * @param {string} qry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsersOrContacts: (qry, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'qry' is not null or undefined
            (0, common_1.assertParamExists)('searchUsersOrContacts', 'qry', qry);
            const localVarPath = `/search/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (qry !== undefined) {
                localVarQueryParameter['qry'] = qry;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.SearchApiAxiosParamCreator = SearchApiAxiosParamCreator;
/**
 * SearchApi - functional programming interface
 * @export
 */
const SearchApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.SearchApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get search global audits
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalAudits(qry, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSearchGlobalAudits(qry, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get search global contacts
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalContacts(qry, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSearchGlobalContacts(qry, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get search global documents
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalDocuments(qry, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSearchGlobalDocuments(qry, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get search global folders
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalFolders(qry, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSearchGlobalFolders(qry, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get search tags
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchTags(qry, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSearchTags(qry, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Search global
         * @param {string} [qry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGlobal(qry, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchGlobal(qry, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Search users or contacts
         * @param {string} qry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsersOrContacts(qry, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchUsersOrContacts(qry, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.SearchApiFp = SearchApiFp;
/**
 * SearchApi - factory interface
 * @export
 */
const SearchApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.SearchApiFp)(configuration);
    return {
        /**
         *
         * @summary Get search global audits
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalAudits(qry, filter, options) {
            return localVarFp.getSearchGlobalAudits(qry, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get search global contacts
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalContacts(qry, filter, options) {
            return localVarFp.getSearchGlobalContacts(qry, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get search global documents
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalDocuments(qry, filter, options) {
            return localVarFp.getSearchGlobalDocuments(qry, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get search global folders
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchGlobalFolders(qry, filter, options) {
            return localVarFp.getSearchGlobalFolders(qry, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get search tags
         * @param {string} [qry]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchTags(qry, filter, options) {
            return localVarFp.getSearchTags(qry, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search global
         * @param {string} [qry]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGlobal(qry, options) {
            return localVarFp.searchGlobal(qry, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search users or contacts
         * @param {string} qry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsersOrContacts(qry, options) {
            return localVarFp.searchUsersOrContacts(qry, options).then((request) => request(axios, basePath));
        },
    };
};
exports.SearchApiFactory = SearchApiFactory;
/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
class SearchApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get search global audits
     * @param {string} [qry]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    getSearchGlobalAudits(qry, filter, options) {
        return (0, exports.SearchApiFp)(this.configuration).getSearchGlobalAudits(qry, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get search global contacts
     * @param {string} [qry]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    getSearchGlobalContacts(qry, filter, options) {
        return (0, exports.SearchApiFp)(this.configuration).getSearchGlobalContacts(qry, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get search global documents
     * @param {string} [qry]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    getSearchGlobalDocuments(qry, filter, options) {
        return (0, exports.SearchApiFp)(this.configuration).getSearchGlobalDocuments(qry, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get search global folders
     * @param {string} [qry]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    getSearchGlobalFolders(qry, filter, options) {
        return (0, exports.SearchApiFp)(this.configuration).getSearchGlobalFolders(qry, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get search tags
     * @param {string} [qry]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    getSearchTags(qry, filter, options) {
        return (0, exports.SearchApiFp)(this.configuration).getSearchTags(qry, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Search global
     * @param {string} [qry]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    searchGlobal(qry, options) {
        return (0, exports.SearchApiFp)(this.configuration).searchGlobal(qry, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Search users or contacts
     * @param {string} qry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    searchUsersOrContacts(qry, options) {
        return (0, exports.SearchApiFp)(this.configuration).searchUsersOrContacts(qry, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.SearchApi = SearchApi;
