import { useQuery } from "@tanstack/react-query";
import { UserApi } from "certiblok-api-manager";
import MLDialog from "../../../../../utils/components/poppers";
import { apiErrorParser, useApi } from "../../../../../utils/hooks/api";

export const plansQueryKeys = {
	all: ["user", "plans"] as const,
	byId: (id: string) => [...plansQueryKeys.all, id],
	usage: () => [...plansQueryKeys.all, "usage"],
	active: () => [...plansQueryKeys.all, "active"],
};

export function useActivePlan() {
	const userApi = useApi(UserApi);

	return useQuery({
		queryKey: plansQueryKeys.active(),
		queryFn: () => userApi.getUserPlan(),
		select: (res) => {
			return {
				...res.data,
				paidRecurrency: res.data?.price?.recurring?.interval as "month" | "year" | undefined,
			};
		},
		onError: (err) => MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" }),
	});
}
