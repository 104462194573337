"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationApi = exports.NotificationApiFactory = exports.NotificationApiFp = exports.NotificationApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * NotificationApi - axios parameter creator
 * @export
 */
const NotificationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary List notifications
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/notification/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Read all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotifications: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/notification/read/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Read single notification
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleNotification: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readSingleNotification', 'id', id);
            const localVarPath = `/notification/read/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Test notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testNotification: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/notification/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.NotificationApiAxiosParamCreator = NotificationApiAxiosParamCreator;
/**
 * NotificationApi - functional programming interface
 * @export
 */
const NotificationApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.NotificationApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary List notifications
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listNotifications(filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Read all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotifications(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readAllNotifications(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Read single notification
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleNotification(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readSingleNotification(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Test notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testNotification(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.testNotification(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.NotificationApiFp = NotificationApiFp;
/**
 * NotificationApi - factory interface
 * @export
 */
const NotificationApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.NotificationApiFp)(configuration);
    return {
        /**
         *
         * @summary List notifications
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications(filter, options) {
            return localVarFp.listNotifications(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Read all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotifications(options) {
            return localVarFp.readAllNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Read single notification
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSingleNotification(id, options) {
            return localVarFp.readSingleNotification(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Test notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testNotification(options) {
            return localVarFp.testNotification(options).then((request) => request(axios, basePath));
        },
    };
};
exports.NotificationApiFactory = NotificationApiFactory;
/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
class NotificationApi extends base_1.BaseAPI {
    /**
     *
     * @summary List notifications
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    listNotifications(filter, options) {
        return (0, exports.NotificationApiFp)(this.configuration).listNotifications(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Read all notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    readAllNotifications(options) {
        return (0, exports.NotificationApiFp)(this.configuration).readAllNotifications(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Read single notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    readSingleNotification(id, options) {
        return (0, exports.NotificationApiFp)(this.configuration).readSingleNotification(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Test notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    testNotification(options) {
        return (0, exports.NotificationApiFp)(this.configuration).testNotification(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.NotificationApi = NotificationApi;
