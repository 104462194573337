import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { PlanApi, PlanUsage } from "certiblok-api-manager";
import { useCallback } from "react";
import { plansQueryKeys } from "../../../../../../../../pages/Profile/components/admin/queries/plansQueries";
import { apiErrorParser, useApi } from "../../../../../../../hooks/api";
import { useAbility } from "../../../../../../../hooks/useAbilityRules";
import MLDialog from "../../../../../../poppers";

export function usePlanUsage() {
	const planApi = useApi(PlanApi);
	const { ability } = useAbility();
	const canViewUsage = ability.can("update", "Company");
	const canReadCompany = ability.can("readAll", "Company");

	const queryClient = useQueryClient();

	const {
		data: planUsage,
		isLoading: planUsageLoading,
		refetch: revalidatePlanUsage,
	} = useQuery({
		queryKey: plansQueryKeys.usage(),
		queryFn: () => {
			return planApi.getCurrentUsage();
		},
		enabled: canReadCompany,
		select: (data) => data?.data,
		onError: (err: any) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	// useEffect(() => {
	// 	const usage = planUsage?.usedStorage ?? 0;
	// 	const percentage = usage / (planUsage?.totalStorage ?? 0);
	// 	// console.log("🚀 ~ useEffect ~ percentage:", percentage, usage);
	// 	if (!usageRef.current || percentage < 0.9 || usageRef.current >= usage || Boolean(isStorageModalOpenRef.current))
	// 		return;
	// 	usageRef.current = usage;
	// 	isStorageModalOpenRef.current = true;
	// 	pushModal(NoStorageModal, { isStorageOver: (planUsage?.totalStorage ?? 0) <= usage }).then(() => {
	// 		isStorageModalOpenRef.current = false;
	// 	});
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [planUsage]);

	const mutatePlanUsageOptimistic = useCallback(
		(newUsage: Partial<PlanUsage>) => {
			queryClient.setQueryData(plansQueryKeys.usage(), (oldUsage: AxiosResponse<PlanUsage> | undefined) => {
				if (!oldUsage) return undefined;
				return { ...oldUsage, data: { ...(oldUsage?.data ?? {}), ...newUsage } };
			});
		},
		[queryClient]
	);

	return {
		canViewUsage,
		planUsage,
		planUsageLoading,
		mutatePlanUsageOptimistic,
		revalidatePlanUsage,
	};
}
