"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * UserApi - axios parameter creator
 * @export
 */
const UserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Check for in use email
         * @param {InputCheckForInUseEmail} [inputCheckForInUseEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkForInUseEmail: (inputCheckForInUseEmail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/alreadyInUseEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCheckForInUseEmail, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Debug weekly resume on user
         * @param {string} xApiKey
         * @param {DebugWeeklyResumeOnUserBody} [debugWeeklyResumeOnUserBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugWeeklyResumeOnUser: (xApiKey, debugWeeklyResumeOnUserBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'xApiKey' is not null or undefined
            (0, common_1.assertParamExists)('debugWeeklyResumeOnUser', 'xApiKey', xApiKey);
            const localVarPath = `/user/sendResume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(debugWeeklyResumeOnUserBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/deleteAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"User\"]
         * @summary Edit profile | Permission required: [\"update\", \"User\"]
         * @param {InputEditProfile} [inputEditProfile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProfile: (inputEditProfile, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/editProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditProfile, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"update\", \"User\"]
         * @summary Edit user preferences | Permission required: [\"update\", \"User\"]
         * @param {InputEditUserPreferences} [inputEditUserPreferences]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserPreferences: (inputEditUserPreferences, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/userPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditUserPreferences, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Get billing portal | Permission required: [\"readOne\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingPortal: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/billingPortal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get invitation
         * @param {InputGetInvitation} [inputGetInvitation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitation: (inputGetInvitation, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/getInvitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputGetInvitation, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/getMe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get recover data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecoverData: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/recover/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get rescued password user
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRescuedPasswordUser: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getRescuedPasswordUser', 'id', id);
            const localVarPath = `/user/{id}/getRescuedPasswordUser`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user company
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompany: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getUserCompany', 'id', id);
            const localVarPath = `/user/{id}/company`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlan: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Invalid current abilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidCurrentAbilities: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/invalidCurrentAbilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Is member of company
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isMemberOfCompany: (email, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'email' is not null or undefined
            (0, common_1.assertParamExists)('isMemberOfCompany', 'email', email);
            const localVarPath = `/user/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary List all users
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllUsers: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Login
         * @param {InputLogin} [inputLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: (inputLogin, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputLogin, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Recover user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverUser: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/recover/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Register user
         * @param {InputRegisterUser} [inputRegisterUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: (inputRegisterUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputRegisterUser, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Rescue password
         * @param {InputRescuePassword} [inputRescuePassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescuePassword: (inputRescuePassword, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/rescuePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputRescuePassword, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send verification
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerification: (token, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'token' is not null or undefined
            (0, common_1.assertParamExists)('sendVerification', 'token', token);
            const localVarPath = `/user/sendVerification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send weekly resume
         * @param {string} xApiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWeeklyResume: (xApiKey, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'xApiKey' is not null or undefined
            (0, common_1.assertParamExists)('sendWeeklyResume', 'xApiKey', xApiKey);
            const localVarPath = `/user/sendResume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (xApiKey !== undefined && xApiKey !== null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set password
         * @param {InputSetPassword} [inputSetPassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword: (inputSetPassword, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/setPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputSetPassword, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Verify user
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUser: (token, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'token' is not null or undefined
            (0, common_1.assertParamExists)('verifyUser', 'token', token);
            const localVarPath = `/user/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.UserApiAxiosParamCreator = UserApiAxiosParamCreator;
/**
 * UserApi - functional programming interface
 * @export
 */
const UserApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.UserApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Check for in use email
         * @param {InputCheckForInUseEmail} [inputCheckForInUseEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkForInUseEmail(inputCheckForInUseEmail, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.checkForInUseEmail(inputCheckForInUseEmail, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Debug weekly resume on user
         * @param {string} xApiKey
         * @param {DebugWeeklyResumeOnUserBody} [debugWeeklyResumeOnUserBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugWeeklyResumeOnUser(xApiKey, debugWeeklyResumeOnUserBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.debugWeeklyResumeOnUser(xApiKey, debugWeeklyResumeOnUserBody, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAccount(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"User\"]
         * @summary Edit profile | Permission required: [\"update\", \"User\"]
         * @param {InputEditProfile} [inputEditProfile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProfile(inputEditProfile, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editProfile(inputEditProfile, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"update\", \"User\"]
         * @summary Edit user preferences | Permission required: [\"update\", \"User\"]
         * @param {InputEditUserPreferences} [inputEditUserPreferences]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserPreferences(inputEditUserPreferences, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editUserPreferences(inputEditUserPreferences, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Get billing portal | Permission required: [\"readOne\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingPortal(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getBillingPortal(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get invitation
         * @param {InputGetInvitation} [inputGetInvitation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitation(inputGetInvitation, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getInvitation(inputGetInvitation, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMe(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get recover data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecoverData(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRecoverData(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get rescued password user
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRescuedPasswordUser(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRescuedPasswordUser(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user company
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompany(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserCompany(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlan(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserPlan(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Invalid current abilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidCurrentAbilities(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.invalidCurrentAbilities(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Is member of company
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isMemberOfCompany(email, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.isMemberOfCompany(email, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary List all users
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllUsers(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listAllUsers(filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Login
         * @param {InputLogin} [inputLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inputLogin, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.login(inputLogin, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.logout(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Recover user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverUser(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.recoverUser(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Register user
         * @param {InputRegisterUser} [inputRegisterUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(inputRegisterUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.registerUser(inputRegisterUser, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Rescue password
         * @param {InputRescuePassword} [inputRescuePassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescuePassword(inputRescuePassword, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.rescuePassword(inputRescuePassword, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Send verification
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerification(token, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendVerification(token, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Send weekly resume
         * @param {string} xApiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWeeklyResume(xApiKey, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendWeeklyResume(xApiKey, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Set password
         * @param {InputSetPassword} [inputSetPassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword(inputSetPassword, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setPassword(inputSetPassword, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Verify user
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUser(token, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.verifyUser(token, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.UserApiFp = UserApiFp;
/**
 * UserApi - factory interface
 * @export
 */
const UserApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.UserApiFp)(configuration);
    return {
        /**
         *
         * @summary Check for in use email
         * @param {InputCheckForInUseEmail} [inputCheckForInUseEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkForInUseEmail(inputCheckForInUseEmail, options) {
            return localVarFp.checkForInUseEmail(inputCheckForInUseEmail, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Debug weekly resume on user
         * @param {string} xApiKey
         * @param {DebugWeeklyResumeOnUserBody} [debugWeeklyResumeOnUserBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugWeeklyResumeOnUser(xApiKey, debugWeeklyResumeOnUserBody, options) {
            return localVarFp.debugWeeklyResumeOnUser(xApiKey, debugWeeklyResumeOnUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(options) {
            return localVarFp.deleteAccount(options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"User\"]
         * @summary Edit profile | Permission required: [\"update\", \"User\"]
         * @param {InputEditProfile} [inputEditProfile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProfile(inputEditProfile, options) {
            return localVarFp.editProfile(inputEditProfile, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"update\", \"User\"]
         * @summary Edit user preferences | Permission required: [\"update\", \"User\"]
         * @param {InputEditUserPreferences} [inputEditUserPreferences]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserPreferences(inputEditUserPreferences, options) {
            return localVarFp.editUserPreferences(inputEditUserPreferences, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Company\"]
         * @summary Get billing portal | Permission required: [\"readOne\", \"Company\"]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingPortal(options) {
            return localVarFp.getBillingPortal(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get invitation
         * @param {InputGetInvitation} [inputGetInvitation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitation(inputGetInvitation, options) {
            return localVarFp.getInvitation(inputGetInvitation, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options) {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get recover data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecoverData(options) {
            return localVarFp.getRecoverData(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get rescued password user
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRescuedPasswordUser(id, options) {
            return localVarFp.getRescuedPasswordUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user company
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompany(id, options) {
            return localVarFp.getUserCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user plan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlan(options) {
            return localVarFp.getUserPlan(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Invalid current abilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidCurrentAbilities(options) {
            return localVarFp.invalidCurrentAbilities(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Is member of company
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isMemberOfCompany(email, options) {
            return localVarFp.isMemberOfCompany(email, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List all users
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllUsers(filter, options) {
            return localVarFp.listAllUsers(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Login
         * @param {InputLogin} [inputLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inputLogin, options) {
            return localVarFp.login(inputLogin, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Recover user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverUser(options) {
            return localVarFp.recoverUser(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Register user
         * @param {InputRegisterUser} [inputRegisterUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(inputRegisterUser, options) {
            return localVarFp.registerUser(inputRegisterUser, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Rescue password
         * @param {InputRescuePassword} [inputRescuePassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescuePassword(inputRescuePassword, options) {
            return localVarFp.rescuePassword(inputRescuePassword, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Send verification
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerification(token, options) {
            return localVarFp.sendVerification(token, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Send weekly resume
         * @param {string} xApiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendWeeklyResume(xApiKey, options) {
            return localVarFp.sendWeeklyResume(xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set password
         * @param {InputSetPassword} [inputSetPassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword(inputSetPassword, options) {
            return localVarFp.setPassword(inputSetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Verify user
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUser(token, options) {
            return localVarFp.verifyUser(token, options).then((request) => request(axios, basePath));
        },
    };
};
exports.UserApiFactory = UserApiFactory;
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
class UserApi extends base_1.BaseAPI {
    /**
     *
     * @summary Check for in use email
     * @param {InputCheckForInUseEmail} [inputCheckForInUseEmail]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    checkForInUseEmail(inputCheckForInUseEmail, options) {
        return (0, exports.UserApiFp)(this.configuration).checkForInUseEmail(inputCheckForInUseEmail, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Debug weekly resume on user
     * @param {string} xApiKey
     * @param {DebugWeeklyResumeOnUserBody} [debugWeeklyResumeOnUserBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    debugWeeklyResumeOnUser(xApiKey, debugWeeklyResumeOnUserBody, options) {
        return (0, exports.UserApiFp)(this.configuration).debugWeeklyResumeOnUser(xApiKey, debugWeeklyResumeOnUserBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    deleteAccount(options) {
        return (0, exports.UserApiFp)(this.configuration).deleteAccount(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"User\"]
     * @summary Edit profile | Permission required: [\"update\", \"User\"]
     * @param {InputEditProfile} [inputEditProfile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    editProfile(inputEditProfile, options) {
        return (0, exports.UserApiFp)(this.configuration).editProfile(inputEditProfile, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"update\", \"User\"]
     * @summary Edit user preferences | Permission required: [\"update\", \"User\"]
     * @param {InputEditUserPreferences} [inputEditUserPreferences]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    editUserPreferences(inputEditUserPreferences, options) {
        return (0, exports.UserApiFp)(this.configuration).editUserPreferences(inputEditUserPreferences, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Company\"]
     * @summary Get billing portal | Permission required: [\"readOne\", \"Company\"]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getBillingPortal(options) {
        return (0, exports.UserApiFp)(this.configuration).getBillingPortal(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get invitation
     * @param {InputGetInvitation} [inputGetInvitation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getInvitation(inputGetInvitation, options) {
        return (0, exports.UserApiFp)(this.configuration).getInvitation(inputGetInvitation, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getMe(options) {
        return (0, exports.UserApiFp)(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get recover data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getRecoverData(options) {
        return (0, exports.UserApiFp)(this.configuration).getRecoverData(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get rescued password user
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getRescuedPasswordUser(id, options) {
        return (0, exports.UserApiFp)(this.configuration).getRescuedPasswordUser(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user company
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getUserCompany(id, options) {
        return (0, exports.UserApiFp)(this.configuration).getUserCompany(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getUserPlan(options) {
        return (0, exports.UserApiFp)(this.configuration).getUserPlan(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Invalid current abilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    invalidCurrentAbilities(options) {
        return (0, exports.UserApiFp)(this.configuration).invalidCurrentAbilities(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Is member of company
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    isMemberOfCompany(email, options) {
        return (0, exports.UserApiFp)(this.configuration).isMemberOfCompany(email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary List all users
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    listAllUsers(filter, options) {
        return (0, exports.UserApiFp)(this.configuration).listAllUsers(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Login
     * @param {InputLogin} [inputLogin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    login(inputLogin, options) {
        return (0, exports.UserApiFp)(this.configuration).login(inputLogin, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    logout(options) {
        return (0, exports.UserApiFp)(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Recover user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    recoverUser(options) {
        return (0, exports.UserApiFp)(this.configuration).recoverUser(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Register user
     * @param {InputRegisterUser} [inputRegisterUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    registerUser(inputRegisterUser, options) {
        return (0, exports.UserApiFp)(this.configuration).registerUser(inputRegisterUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Rescue password
     * @param {InputRescuePassword} [inputRescuePassword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    rescuePassword(inputRescuePassword, options) {
        return (0, exports.UserApiFp)(this.configuration).rescuePassword(inputRescuePassword, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Send verification
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    sendVerification(token, options) {
        return (0, exports.UserApiFp)(this.configuration).sendVerification(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Send weekly resume
     * @param {string} xApiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    sendWeeklyResume(xApiKey, options) {
        return (0, exports.UserApiFp)(this.configuration).sendWeeklyResume(xApiKey, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set password
     * @param {InputSetPassword} [inputSetPassword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    setPassword(inputSetPassword, options) {
        return (0, exports.UserApiFp)(this.configuration).setPassword(inputSetPassword, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Verify user
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    verifyUser(token, options) {
        return (0, exports.UserApiFp)(this.configuration).verifyUser(token, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.UserApi = UserApi;
