import { useCallback, useMemo } from "react";

type FeatureName = "editRoom" | "qrCode" | "guestInvites" | "cloudImport";

type FeatureAbility = {
	[feature in FeatureName]: boolean;
};

export const useFeatureFlags = () => {
	const featuresAbilitation: FeatureAbility = useMemo(
		() => ({ editRoom: true, qrCode: true, guestInvites: true, cloudImport: true }),
		[]
	);

	const isFeatureEnabled = useCallback(
		(feature: FeatureName) => {
			return featuresAbilitation[feature];
		},
		[featuresAbilitation]
	);

	return { featuresAbilitation, isFeatureEnabled };
};
