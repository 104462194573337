/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import ImportFromCloudModal from "../../../pages/Profile/components/ImportFromCloud/ImportFromCloudModal";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import { useShouldShowImportFromCloudModal } from "../../hooks/useShouldShowFirstTimeModal";
import { GlobalCalendarContext, useGlobalCalendarContext } from "../globalCalendar/controllers/GlobalCalendarContext";
import AppBar from "./AppBar";
import Body from "./Body";
import Drawer from "./components/Drawer";
import ImportLog from "./components/ImportLog";
import SendRequestModal from "./components/SendRequestModal";
import SendVideoConferenceModal from "./components/SendVideoConferenceModal";
import { ScaffoldContext, useScaffoldContext } from "./controllers/ScaffoldContext";

type Props = {};

const Scaffold: React.FC<Props> = () => {
	const scaffoldContext = useScaffoldContext();
	const globalCalendarContext = useGlobalCalendarContext();
	const [openImportModal, setOpenImportModal] = useState(false);

	const { shouldShowCloudImportModal, setShouldShowCloudImportModal } = useShouldShowImportFromCloudModal();

	useEffect(() => {
		// @ts-ignore
		if (shouldShowCloudImportModal === "true") {
			setShouldShowCloudImportModal(false);
			setOpenImportModal(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldShowCloudImportModal]);

	const isSm = useBetterMediaQuery("sm");

	return (
		<GlobalCalendarContext.Provider value={globalCalendarContext}>
			<ScaffoldContext.Provider value={scaffoldContext}>
				<Box
					sx={{
						backgroundColor: "background.default",
						overflow: "hidden",
						width: "100%",
						height: "100%",
						position: "relative",
					}}
				>
					<Drawer />
					<AppBar />
					<Body />
					{isSm && <ImportLog />}
					<SendRequestModal
						open={scaffoldContext.sendRequestModalOpen}
						toggleOpen={scaffoldContext.toggleSendRequestModalOpen}
					/>
					<SendVideoConferenceModal
						open={scaffoldContext.sendVideoConferenceModalOpen}
						toggleOpen={scaffoldContext.toggleSendVideoConferenceModalOpen}
					/>
					<ImportFromCloudModal open={openImportModal} setOpen={setOpenImportModal} isFirstLogin />
				</Box>
			</ScaffoldContext.Provider>
		</GlobalCalendarContext.Provider>
	);
};

export default Scaffold;
