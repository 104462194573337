/** @jsxImportSource @emotion/react */
import { alpha, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LogoutModalWithNiceModal } from "../../../../../pages/Profile/components/LogoutModal";
import { useGetCompanyAdmins } from "../../../../../pages/Profile/pages/Team/hooks/useGetCompanyAdmin";
import { LG_DRAWER_CLOSED_WIDTH, LG_DRAWER_OPEN_WIDTH } from "../../../../constants/DrawerConstants";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import { useMe } from "../../../../hooks/useMe";
import { CertiblokIconNames, default as CertiblokIcon } from "../../../icons/CertiblokIcon";
import { useModalNavigator } from "../../../ModalNavigator/ModalNavigator";
import StyledDrawer from "../../../StyledDrawer";
import { ScaffoldContext } from "../../controllers/ScaffoldContext";
import { CollapsableImage } from "../CollapsableImage";
import CollapsableButtons from "./components/CollapsableButtons";
import { DrawerUserCard } from "./components/DrawerUserCard";
import { RouteButton } from "./components/RouteButton";

export type RouteInfos = {
	title: string;
	iconName?: CertiblokIconNames;
} & ({ subroutes: RouteInfos[]; path?: never } | { path: string; subroutes?: never });

const Drawer = () => {
	const { t } = useTranslation();
	const { open, setOpen, lockDrawerOpen, setLockDrawerOpen } = useContext(ScaffoldContext);
	const { pushModal } = useModalNavigator();
	const location = useLocation();
	const theme = useTheme();

	const { isFeatureEnabled } = useFeatureFlags();
	const isEditRoomEnabled = isFeatureEnabled("editRoom");

	const { data: companyGuests } = useGetCompanyAdmins();
	const { me } = useMe();
	const isUserGuest = companyGuests?.some((admin) => admin.id === me?.id);

	const availableSections: RouteInfos[] = [
		{
			iconName: "home_outline",
			title: t("scaffold.dashboard"),
			path: "/",
		},
		{
			iconName: "folder_outline",
			title: t("folders.myFolders"),
			path: "/folders",
		},
		{
			title: t("global.documents"),
			iconName: "paper_outline_02",
			subroutes: [
				{
					title: t("dashboard.inboxDocuments"),
					path: "/inbox",
				},
				{
					title: t("global.allDocuments"),
					path: "/all_documents",
				},
				{
					title: t("tags.documentsByTag"),
					path: "/tags",
				},
				{
					title: t("favorites.favorites"),
					path: "/favorites",
				},
				{
					title: t("scaffold.requests"),
					path: "/requests",
				},
			],
		},
		...(!Boolean(isUserGuest)
			? [
					{
						title: t("documents.auditRoom"),
						iconName: "profile_outline_01" as CertiblokIconNames,
						subroutes: [
							{
								title: t("auditRooms.auditRoomList"),
								path: "/audit_rooms",
							},
							{
								title: t("auditRooms.archive"),
								path: "/audit_rooms/archive",
							},
						],
					},
			  ]
			: []),

		...(isEditRoomEnabled
			? [
					{
						title: "Edit Room",
						path: "/edit_rooms",
						iconName: "edit-room_outline" as CertiblokIconNames,
					},
			  ]
			: []),
		{
			iconName: "call_outline_03",
			title: t("contacts.list"),
			// path: "/contacts",
			subroutes: [
				{ title: t("contacts.singleContacts"), path: "/contacts?section=single" },
				{ title: t("contactsGroups.contactsGroups"), path: "/contacts?section=groups" },
			],
		},
		{
			iconName: "delete_outline",
			title: t("scaffold.bin"),
			path: "/bin",
		},
		{
			iconName: "settings_outline",
			title: t("profile.settings"),
			path: "/profile/settings",
		},
	];

	const isMd = useMediaQuery(theme.breakpoints.up("md"));
	const isXl = useMediaQuery(theme.breakpoints.up("xl"));
	const isExactlyLg = useMediaQuery(theme.breakpoints.only("lg"));
	const isUnderMd = useMediaQuery(theme.breakpoints.down("md"));

	const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined);

	const closeDrawer = () => {
		if (!isXl && !lockDrawerOpen) {
			setOpen(false);
			setExpandedIndex(undefined);
		}
	};

	const drawerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!isExactlyLg) {
			setLockDrawerOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isExactlyLg]);

	useEffect(() => {
		if (isUnderMd) {
			setOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUnderMd]);

	useEffect(() => {
		if (open) drawerRef.current?.firstElementChild?.scrollTo({ top: 0 });
	}, [open]);

	return (
		<StyledDrawer
			ref={drawerRef}
			open={open}
			variant="permanent"
			anchor="left"
			sx={{
				width: { xs: "100vw", md: open ? LG_DRAWER_OPEN_WIDTH : LG_DRAWER_CLOSED_WIDTH },
				"& .MuiPaper-root": {
					zIndex: 1100,
					height: "100%",
					padding: 4,
					boxSizing: "border-box",
					py: 3,
					gap: { xs: 3, md: 4 },
					width: {
						xs: "100vw",
						md: open ? LG_DRAWER_OPEN_WIDTH : LG_DRAWER_CLOSED_WIDTH,
					},
				},
			}}
		>
			<Box
				sx={{
					boxSizing: "border-box",
					display: "flex",
					flexDirection: "column",
					alignItems: "start",
					gap: { xs: 5, lg: 9 },
					paddingX: 2,
				}}
			>
				<Box sx={{ height: 48, visibility: { xs: "hidden", md: "unset" }, paddingLeft: 1 }}>
					<CollapsableImage open={isMd ? open : true} />
				</Box>
			</Box>

			<div className="overflow-y-scroll overflow-x-visible flex-1 flex flex-col justify-between gap-3">
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						overflowX: "visible",
						flexGrow: 1,
						flexShrink: 1,
						alignItems: "center",
						gap: 1,
						"@media (min-height: 800px)": {
							gap: 3,
						},
					}}
				>
					{availableSections.map((section, i) => (
						<RouteButton
							key={section.title}
							expanded={i === expandedIndex}
							onExpand={() => (expandedIndex === i ? setExpandedIndex(undefined) : setExpandedIndex(i))}
							onClick={() => {
								closeDrawer();
							}}
							open={isMd ? open : true}
							selected={
								section?.subroutes
									? section.subroutes
											.map((route) => route?.path)
											.some((v) => v && (location.pathname + location.search).includes(v))
									: section.path === "/"
									? location.pathname === section.path
									: location.pathname.includes(section.path)
							}
							{...section}
						/>
					))}

					<CollapsableButtons />
					{/* <SideBarUsage open={isMd ? open : true} /> */}
				</Box>
				<DrawerUserCard onClick={closeDrawer} />
				{!isMd && (
					<Button
						className="mt-2"
						fullWidth
						variant="text"
						sx={{
							backgroundColor: alpha(theme.palette.primary.main, 0.12),
						}}
						onClick={() => {
							pushModal(LogoutModalWithNiceModal);
						}}
					>
						Logout
						<CertiblokIcon name="log_outline_01" color="inherit" />
					</Button>
				)}
			</div>
		</StyledDrawer>
	);
};

export default Drawer;
