"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropboxApi = exports.DropboxApiFactory = exports.DropboxApiFp = exports.DropboxApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * DropboxApi - axios parameter creator
 * @export
 */
const DropboxApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get dropbox folder
         * @param {string} [pageToken]
         * @param {string} [query]
         * @param {string} [parentId]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxFolder: (pageToken, query, parentId, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/dropbox/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }
            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }
            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Import files from dropbox
         * @param {InputImportFilesFromDropbox} inputImportFilesFromDropbox
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFilesFromDropbox: (inputImportFilesFromDropbox, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputImportFilesFromDropbox' is not null or undefined
            (0, common_1.assertParamExists)('importFilesFromDropbox', 'inputImportFilesFromDropbox', inputImportFilesFromDropbox);
            const localVarPath = `/dropbox/import/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputImportFilesFromDropbox, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DropboxApiAxiosParamCreator = DropboxApiAxiosParamCreator;
/**
 * DropboxApi - functional programming interface
 * @export
 */
const DropboxApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.DropboxApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get dropbox folder
         * @param {string} [pageToken]
         * @param {string} [query]
         * @param {string} [parentId]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxFolder(pageToken, query, parentId, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDropboxFolder(pageToken, query, parentId, sort, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Import files from dropbox
         * @param {InputImportFilesFromDropbox} inputImportFilesFromDropbox
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFilesFromDropbox(inputImportFilesFromDropbox, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.importFilesFromDropbox(inputImportFilesFromDropbox, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.DropboxApiFp = DropboxApiFp;
/**
 * DropboxApi - factory interface
 * @export
 */
const DropboxApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.DropboxApiFp)(configuration);
    return {
        /**
         *
         * @summary Get dropbox folder
         * @param {string} [pageToken]
         * @param {string} [query]
         * @param {string} [parentId]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxFolder(pageToken, query, parentId, sort, options) {
            return localVarFp.getDropboxFolder(pageToken, query, parentId, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Import files from dropbox
         * @param {InputImportFilesFromDropbox} inputImportFilesFromDropbox
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFilesFromDropbox(inputImportFilesFromDropbox, options) {
            return localVarFp.importFilesFromDropbox(inputImportFilesFromDropbox, options).then((request) => request(axios, basePath));
        },
    };
};
exports.DropboxApiFactory = DropboxApiFactory;
/**
 * DropboxApi - object-oriented interface
 * @export
 * @class DropboxApi
 * @extends {BaseAPI}
 */
class DropboxApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get dropbox folder
     * @param {string} [pageToken]
     * @param {string} [query]
     * @param {string} [parentId]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    getDropboxFolder(pageToken, query, parentId, sort, options) {
        return (0, exports.DropboxApiFp)(this.configuration).getDropboxFolder(pageToken, query, parentId, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Import files from dropbox
     * @param {InputImportFilesFromDropbox} inputImportFilesFromDropbox
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DropboxApi
     */
    importFilesFromDropbox(inputImportFilesFromDropbox, options) {
        return (0, exports.DropboxApiFp)(this.configuration).importFilesFromDropbox(inputImportFilesFromDropbox, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DropboxApi = DropboxApi;
