import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ImportFromCloudModal from "../../../../../../../pages/Profile/components/ImportFromCloud/ImportFromCloudModal";
import { useBetterMediaQuery } from "../../../../../../hooks/useBetterMediaQuery";
import { useFeatureFlags } from "../../../../../../hooks/useFeatureFlags";
import CertiblokIcon from "../../../../../icons/CertiblokIcon";
import { ScaffoldContext } from "../../../../controllers/ScaffoldContext";
import CollapsableButton from "../CollapsableButton";

const CollapsableButtons = () => {
	const { t } = useTranslation();
	const { open, toggleSendVideoConferenceModalOpen } = useContext(ScaffoldContext);

	const isMd = useBetterMediaQuery("md");

	const { isFeatureEnabled } = useFeatureFlags();
	const isCloudImportEnabled = isFeatureEnabled("cloudImport");

	const [importModalOpen, setImportModalOpen] = useState(false);

	return (
		<div className="flex flex-col gap-4 w-full mt-6">
			<CollapsableButton
				open={isMd ? open : true}
				icon={<CertiblokIcon size={24} color="inherit" name={"cloud_outline"} />}
				children={t("cloudImport.importFromOtherClouds")}
				color="secondary"
				variant="contained"
				{...((open || !isMd) && { fullWidth: true })}
				sx={{
					gap: 2,
					height: 40,
					flexShrink: 0,
					backgroundColor: "#8ed951",
					":hover": {
						backgroundColor: "#92df53",
						":disabled": {
							backgroundColor: "rgba(0,0,0,0.12)",
						},
					},
				}}
				disabled={!isCloudImportEnabled}
				size="medium"
				onClick={() => {
					if (isCloudImportEnabled) {
						setImportModalOpen(true);
					}
				}}
			/>

			<CollapsableButton
				open={isMd ? open : true}
				icon={<CertiblokIcon size={24} color="inherit" name={"folder_outline"} />}
				children={t("dashboard.syncFiles")}
				variant="contained"
				color="secondary"
				{...((open || !isMd) && { fullWidth: true })}
				sx={{
					gap: 2,
					height: 40,
					flexShrink: 0,
					backgroundColor: "#f08826",
					":hover": {
						backgroundColor: "#f18f34",
						":disabled": {
							backgroundColor: "rgba(0,0,0,0.12)",
						},
					},
				}}
				disabled={true}
				size="medium"
				onClick={() => {
					// TODO MEM: manca l'azione
				}}
			/>

			<CollapsableButton
				open={isMd ? open : true}
				icon={<CertiblokIcon size={24} color="inherit" name={"video_outline"} />}
				children={t("variousComponents.videoConference")}
				variant="contained"
				color="secondary"
				{...((open || !isMd) && { fullWidth: true })}
				sx={{
					gap: 2,
					height: 40,
					flexShrink: 0,
					backgroundColor: "#56c1fe",
					":hover": {
						backgroundColor: "#70c3ff",
					},
				}}
				size="medium"
				onClick={() => {
					toggleSendVideoConferenceModalOpen();
				}}
			/>
			<ImportFromCloudModal open={importModalOpen} setOpen={setImportModalOpen} />
		</div>
	);
};

export default CollapsableButtons;
