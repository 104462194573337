"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventApi = exports.EventApiFactory = exports.EventApiFp = exports.EventApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * EventApi - axios parameter creator
 * @export
 */
const EventApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create event
         * @param {EventCreateEditInput} [eventCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent: (eventCreateEditInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/event/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(eventCreateEditInput, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete event
         * @param {InputDeleteEvent} [inputDeleteEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent: (inputDeleteEvent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/event/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputDeleteEvent, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit attendee role
         * @param {string} id
         * @param {InputEditAttendeeRole} [inputEditAttendeeRole]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAttendeeRole: (id, inputEditAttendeeRole, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editAttendeeRole', 'id', id);
            const localVarPath = `/event/instance/{id}/attendee/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditAttendeeRole, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit event
         * @param {string} id
         * @param {EventCreateEditInput} [eventCreateEditInput]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        editEvent: (id, eventCreateEditInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editEvent', 'id', id);
            const localVarPath = `/event/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(eventCreateEditInput, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit event instance by id
         * @param {string} id
         * @param {EventInstanceEditInput} [eventInstanceEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEventInstanceById: (id, eventInstanceEditInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editEventInstanceById', 'id', id);
            const localVarPath = `/event/instance/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(eventInstanceEditInput, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getEventById', 'id', id);
            const localVarPath = `/event/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get event instance by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventInstanceById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getEventInstanceById', 'id', id);
            const localVarPath = `/event/instance/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Invite to event
         * @param {InputInviteToEvent} [inputInviteToEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteToEvent: (inputInviteToEvent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/event/invite/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputInviteToEvent, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove attendee from event
         * @param {InputRemoveAttendeeFromEvent} [inputRemoveAttendeeFromEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAttendeeFromEvent: (inputRemoveAttendeeFromEvent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/event/invite/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputRemoveAttendeeFromEvent, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Review participation
         * @param {InputReviewParticipation} [inputReviewParticipation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewParticipation: (inputReviewParticipation, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/event/invite/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputReviewParticipation, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.EventApiAxiosParamCreator = EventApiAxiosParamCreator;
/**
 * EventApi - functional programming interface
 * @export
 */
const EventApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.EventApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create event
         * @param {EventCreateEditInput} [eventCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(eventCreateEditInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createEvent(eventCreateEditInput, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete event
         * @param {InputDeleteEvent} [inputDeleteEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(inputDeleteEvent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteEvent(inputDeleteEvent, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit attendee role
         * @param {string} id
         * @param {InputEditAttendeeRole} [inputEditAttendeeRole]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAttendeeRole(id, inputEditAttendeeRole, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editAttendeeRole(id, inputEditAttendeeRole, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit event
         * @param {string} id
         * @param {EventCreateEditInput} [eventCreateEditInput]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        editEvent(id, eventCreateEditInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editEvent(id, eventCreateEditInput, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit event instance by id
         * @param {string} id
         * @param {EventInstanceEditInput} [eventInstanceEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEventInstanceById(id, eventInstanceEditInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editEventInstanceById(id, eventInstanceEditInput, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getEventById(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get event instance by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventInstanceById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getEventInstanceById(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Invite to event
         * @param {InputInviteToEvent} [inputInviteToEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteToEvent(inputInviteToEvent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.inviteToEvent(inputInviteToEvent, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove attendee from event
         * @param {InputRemoveAttendeeFromEvent} [inputRemoveAttendeeFromEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAttendeeFromEvent(inputRemoveAttendeeFromEvent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeAttendeeFromEvent(inputRemoveAttendeeFromEvent, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Review participation
         * @param {InputReviewParticipation} [inputReviewParticipation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewParticipation(inputReviewParticipation, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.reviewParticipation(inputReviewParticipation, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.EventApiFp = EventApiFp;
/**
 * EventApi - factory interface
 * @export
 */
const EventApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.EventApiFp)(configuration);
    return {
        /**
         *
         * @summary Create event
         * @param {EventCreateEditInput} [eventCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(eventCreateEditInput, options) {
            return localVarFp.createEvent(eventCreateEditInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete event
         * @param {InputDeleteEvent} [inputDeleteEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(inputDeleteEvent, options) {
            return localVarFp.deleteEvent(inputDeleteEvent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit attendee role
         * @param {string} id
         * @param {InputEditAttendeeRole} [inputEditAttendeeRole]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAttendeeRole(id, inputEditAttendeeRole, options) {
            return localVarFp.editAttendeeRole(id, inputEditAttendeeRole, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit event
         * @param {string} id
         * @param {EventCreateEditInput} [eventCreateEditInput]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        editEvent(id, eventCreateEditInput, options) {
            return localVarFp.editEvent(id, eventCreateEditInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit event instance by id
         * @param {string} id
         * @param {EventInstanceEditInput} [eventInstanceEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editEventInstanceById(id, eventInstanceEditInput, options) {
            return localVarFp.editEventInstanceById(id, eventInstanceEditInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventById(id, options) {
            return localVarFp.getEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get event instance by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventInstanceById(id, options) {
            return localVarFp.getEventInstanceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Invite to event
         * @param {InputInviteToEvent} [inputInviteToEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteToEvent(inputInviteToEvent, options) {
            return localVarFp.inviteToEvent(inputInviteToEvent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove attendee from event
         * @param {InputRemoveAttendeeFromEvent} [inputRemoveAttendeeFromEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAttendeeFromEvent(inputRemoveAttendeeFromEvent, options) {
            return localVarFp.removeAttendeeFromEvent(inputRemoveAttendeeFromEvent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Review participation
         * @param {InputReviewParticipation} [inputReviewParticipation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewParticipation(inputReviewParticipation, options) {
            return localVarFp.reviewParticipation(inputReviewParticipation, options).then((request) => request(axios, basePath));
        },
    };
};
exports.EventApiFactory = EventApiFactory;
/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
class EventApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create event
     * @param {EventCreateEditInput} [eventCreateEditInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    createEvent(eventCreateEditInput, options) {
        return (0, exports.EventApiFp)(this.configuration).createEvent(eventCreateEditInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete event
     * @param {InputDeleteEvent} [inputDeleteEvent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    deleteEvent(inputDeleteEvent, options) {
        return (0, exports.EventApiFp)(this.configuration).deleteEvent(inputDeleteEvent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit attendee role
     * @param {string} id
     * @param {InputEditAttendeeRole} [inputEditAttendeeRole]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    editAttendeeRole(id, inputEditAttendeeRole, options) {
        return (0, exports.EventApiFp)(this.configuration).editAttendeeRole(id, inputEditAttendeeRole, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit event
     * @param {string} id
     * @param {EventCreateEditInput} [eventCreateEditInput]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EventApi
     */
    editEvent(id, eventCreateEditInput, options) {
        return (0, exports.EventApiFp)(this.configuration).editEvent(id, eventCreateEditInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit event instance by id
     * @param {string} id
     * @param {EventInstanceEditInput} [eventInstanceEditInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    editEventInstanceById(id, eventInstanceEditInput, options) {
        return (0, exports.EventApiFp)(this.configuration).editEventInstanceById(id, eventInstanceEditInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get event by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    getEventById(id, options) {
        return (0, exports.EventApiFp)(this.configuration).getEventById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get event instance by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    getEventInstanceById(id, options) {
        return (0, exports.EventApiFp)(this.configuration).getEventInstanceById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Invite to event
     * @param {InputInviteToEvent} [inputInviteToEvent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    inviteToEvent(inputInviteToEvent, options) {
        return (0, exports.EventApiFp)(this.configuration).inviteToEvent(inputInviteToEvent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove attendee from event
     * @param {InputRemoveAttendeeFromEvent} [inputRemoveAttendeeFromEvent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    removeAttendeeFromEvent(inputRemoveAttendeeFromEvent, options) {
        return (0, exports.EventApiFp)(this.configuration).removeAttendeeFromEvent(inputRemoveAttendeeFromEvent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Review participation
     * @param {InputReviewParticipation} [inputReviewParticipation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    reviewParticipation(inputReviewParticipation, options) {
        return (0, exports.EventApiFp)(this.configuration).reviewParticipation(inputReviewParticipation, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.EventApi = EventApi;
