"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationTypeEnum = void 0;
exports.NotificationTypeEnum = {
    Storage: 'storage',
    NewTeamRequest: 'new_team_request',
    TeamRequestRetired: 'team_request_retired',
    UploadDocumentRequest: 'upload_document_request',
    DocumentExpiration: 'document_expiration',
    NewSharedDocument: 'new_shared_document',
    NewSharedNoteDocument: 'new_shared_note_document',
    UpdatedDocument: 'updated_document',
    ClosedAuditRoom: 'closed_audit_room',
    UpdateDocumentRequest: 'update_document_request',
    AsAuditor: 'as_auditor',
    ExpiringAuditRoom: 'expiring_audit_room',
    NewDocumentsInAudit: 'new_documents_in_audit',
    DocumentModified: 'document_modified',
    EventInvitation: 'event_invitation',
    EventAccepted: 'event_accepted',
    EventRejected: 'event_rejected',
    EventUpdated: 'event_updated',
    EventDeleted: 'event_deleted',
    EventReminder: 'event_reminder',
    MemberInvitationAccepted: 'member_invitation_accepted',
    MemberInvitationRejected: 'member_invitation_rejected',
    AdminInvitationAccepted: 'admin_invitation_accepted',
    AdminInvitationRejected: 'admin_invitation_rejected',
    AdminInvitation: 'admin_invitation',
    EditRoomDocumentAccessRequest: 'edit_room_document_access_request',
    DocumentChatMessage: 'document_chat_message'
};
